.cb-checkbox .cb-inner,
.cb-checkbox i {
  border-radius: 3px;
  height: 24px;
  width: 24px;
}

.cb-checkbox {
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: normal;
  line-height: 18px;
  overflow: hidden;
  padding: 3px 0;
}

.cb-checkbox input[type="checkbox"],
.cb-radio input[type="radio"] {
  display: none;
}

.cb-checkbox .cb-inner {
  background: #fff none repeat scroll 0 0;
  border: 2px solid #d6d6d6;
  display: inline-block;
  float: left;
  margin: 0 5px 0 0;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease 0s;
}

.cb-checkbox i {
  display: block;
  left: 2px;
  position: absolute;
  top: 1px;
}

.cb-checkbox i:before,
.cb-checkbox i:after {
  /* color of the check mark */
  background-color: var(--cor-primaria);
  content: "";
  display: block;
  height: 0;
  position: absolute;
  transition: all 0.2s ease 0s;
  width: 3px;
}

.cb-checkbox i:before {
  left: 0;
  top: 0;
  transform: rotate(-55deg);
}

/* change colors here... */

.cb-checkbox.checked .cb-inner {
  background-color: #fff;
  border-color: var(--cor-primaria);
}

.cb-checkbox i:after {
  bottom: 9px;
  left: 10px;
  transform: rotate(30deg);
  transition-delay: 0.3s;
}

.cb-checkbox.checked i:before {
  height: 6px;
  left: 5px;
  top: 8px;
  color: red;
}

.cb-checkbox.checked i::after {
  height: 12px;
  color: red;
}

.cb-checkbox:hover .cb-inner,
.cb-radio:hover .cb-inner {
  border-color: var(--cor-primaria);
}

.cb-checkbox-danger.checked .cb-inner {
  border-color: var(--cor-secundaria);
}

.cb-checkbox-danger:hover .cb-inner,
.cb-radio:hover .cb-inner {
  border-color: var(--cor-secundaria);
}

.cb-checkbox-danger i:before,
.cb-checkbox-danger i:after {
  /* color of the check mark */
  background-color: var(--cor-secundaria);
}

.cb-checkbox-success.checked .cb-inner {
  border-color: #28a745;
}

.cb-checkbox-success:hover .cb-inner,
.cb-radio:hover .cb-inner {
  border-color: #28a745;
}

.cb-checkbox-success i:before,
.custom-checkbox:checked,
.cb-checkbox-success i:after {
  /* color of the check mark */
  background-color: #28a745;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #28a745;
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--cor-primaria);
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider-danger {
  background-color: #e94e25;
}

input:focus + .slider-danger {
  box-shadow: 0 0 1p#E94E25;
}

input:checked + .slider-success {
  background-color: #28a745;
}

input:focus + .slider-success {
  box-shadow: 0 0 1px #28a745;
}

input:checked + .slider-bege {
  background-color: #c5c84e;
}

input:focus + .slider-bege {
  box-shadow: 0 0 1px #c5c84e;
}

input:checked + .slider-secondary {
  background-color: #6c757d;
}

input:focus + .slider-secondary {
  box-shadow: 0 0 1px #6c757d;
}
